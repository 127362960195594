/*--------------------------------------------------------------
>>> TABLE OF CONTENTS:
----------------------------------------------------------------
# Imports
	# Blocks
	# Media
		# Captions
		# Galleries (Legacy)
# Post and page content
	# Alignments
	# Custom block colors
	# Custom font sizes
--------------------------------------------------------------*/

/*--------------------------------------------------------------
# Imports
--------------------------------------------------------------*/
@import "_custom-properties.scss";
@import "_blocks.scss";
@import "_media.scss";
@import "_pagination.scss";
@import "_widgets.scss";
@import "_grid.scss";

/*--------------------------------------------------------------
# Post and page content
--------------------------------------------------------------*/

/* Custom rule  for sticky posts: */
article.sticky {
	font-size: inherit;
}
.entry {
    box-shadow: none;
}
.entry-loop {
	overflow: hidden;
}
.content-wrap {
    position: relative;
    //-index: 1;
}
.brand {
	.elementor-image-gallery {
		text-align: center;
	}
}

.thebase-thumbnail-position-behind + .entry {
    z-index: 1;
    position: relative;
}
@media screen and (max-width: $phone-minus-query) {
	.content-style-boxed .content-bg:not(.loop-entry) {
		margin-inline-start: -1rem;
		margin-inline-end: -1rem;
		width: auto;
	}
	.content-style-boxed .comments-area {
		padding-inline-start: 0.5rem;
		padding-inline-end: 0.5rem;
	}
	.primary-sidebar {
		padding-inline-start: 0;
		padding-inline-end: 0;
	}
	.site .grid-lg-col-1.item-image-style-beside article.loop-entry {
		.post-thumbnail.thebase-thumbnail-ratio-5-4 {
			padding-block-end: 100%;
		}
		.post-thumbnail.thebase-thumbnail-ratio-4-3 {
			padding-block-end: 100%;
		}
		.post-thumbnail.thebase-thumbnail-ratio-3-2 {
			padding-block-end: 100%;
		}
	}
}
@media screen and (min-width: $tablet-plus-query) {
	.grid-lg-col-1.item-image-style-beside article.loop-entry.has-post-thumbnail:not( .kb-post-no-image ) {
		display: grid;
		grid-template-columns: 1fr 2fr;
	}
	.blog.content-style-unboxed, .archive.content-style-unboxed {
		.grid-lg-col-1.item-image-style-beside article.loop-entry {
			grid-gap: 2rem;
			.post-thumbnail {
				margin-block-end: 0;
			}
		}
	}
}
.archive.content-style-unboxed, .blog.content-style-unboxed, .post-type-archive.content-style-unboxed, .search-results.content-style-unboxed {
	.entry-content-wrap {		
		padding-inline: 0;
		padding-block: 25px;
	}
	.entry {			
			position: relative;
			display: inline-block;
			vertical-align: top;
			border-radius: 0;	
			margin-block-end: 10px;	
	}
}

.single-content {
	margin: var(--global-md-spacing) 0 0;
	address, figure, hr, p, table, pre, form, .wp-block-buttons, .wp-block-button, .wc-block-grid, .wp-block-thebase-advancedbtn, .wp-block-media-text, .wp-block-cover, .entry-content-asset, .wp-block-thebase-form, .wp-block-group, .wp-block-yoast-faq-block, .wp-block-yoast-how-to-block, .schema-faq-section, .wp-block-thebase-tableofcontents, .enlighter-default, .wp-block-thebase-iconlist, [class*=__inner-content] {
		margin-block-start: 0;
		margin-block-end:var(--global-md-spacing);
	}
	.wp-block-buttons .wp-block-button {
		margin-block-end: 0;
	}
	// This removes the extra space when adding a spacer below the paragraph.
	p:not(.wp-block-thebase-advancedheading) + .wp-block-thebase-spacer {
		margin-block-start: calc( 0rem - var(--global-md-spacing) );
	}
	.wp-block-yoast-faq-block .schema-faq-question {
		margin-block-end: .5em;
	}
	h1, h2, h3, h4, h5{
		margin: .5em 0;
		&:first-child {
			margin-block-start: 0;
		}
		// Useful if you add one heading after another.
		&+* {
			margin-block-start: 0;
		}
	}
	.wp-block-thebase-advancedgallery + p {
		margin-block-start: var(--global-md-spacing);
	}
	ul, ol {
		margin-left: 0;
		margin-bottom: var(--global-md-spacing);
		padding-inline-start: 1.5em;
		&.aligncenter {
			text-align: center;
			padding-inline-start: 0;
		}
	}
	.tbt-slider ul {
		padding: 0;
		margin: 0;
	}
	li>ol, li>ul {
		margin: 0;
	}
	/* Align .more-link with the content. */
	> .more-link {
		display: block;
	}
	address, figure, hr, p, table, pre, form, .wp-block-buttons, .wp-block-button, .wp-block-thebase-advancedbtn, .wp-block-media-text, .wp-block-cover, .entry-content-asset, .wp-block-thebase-form, .wp-block-group, .wp-block-yoast-faq-block, .wp-block-yoast-how-to-block, .schema-faq-section, .wp-block-thebase-tableofcontents, .enlighter-default, .wp-block-thebase-iconlist, h1, h2, h3, h4, h5, h6, ul, ol {
		&:last-child {
			margin-block-end: 0;
			// > figure {
			// 	margin-block-start: 0;
			// }
		}
	}
	h1.elementor-heading-title, h2.elementor-heading-title, h3.elementor-heading-title, h4.elementor-heading-title, h5.elementor-heading-title, h6.elementor-heading-title  {
		margin-block-end: 0.5em;
	}
	.wp-block-image {
		margin-block-start: 0;
		margin-block-end: 0;
		figure {
			margin-block-end: var(--global-sm-spacing);
		}
		&:last-child {
			figure {
				margin-block-end: 0;
			}
		}
	}
	figure.wp-block-image {
		margin-block-end: var(--global-sm-spacing);
		&:last-child {
			margin-block-end: 0;
		}
	}
}
@media screen and (max-width: $phone-minus-query) {
	.single-content {
		h1, h2, h3, h4, h5, h6 {
			margin: 0;
		}
	}
}
.entry-content:after {
    display: table;
    clear: both;
    content: '';
}
.content-title-style-hide .entry-content, .content-title-style-above .entry-content {
    margin: 0;
}
.content-title-style-above .entry-content-wrap .post-thumbnail + .single-content {
    margin: var(--global-md-spacing) 0 0;
}
.site .post-thumbnail {
	display: block;
	height: 0;
    padding-block-end: 66.67%;
    overflow: hidden;
	position: relative;
	&.thebase-thumbnail-ratio-1-2 {
		padding-block-end: 50%;
	}
	&.thebase-thumbnail-ratio-9-16 {
		padding-block-end: 56.25%;
	}
	&.thebase-thumbnail-ratio-1-1 {
		padding-block-end: 100%;
	}
	&.thebase-thumbnail-ratio-3-4 {
		padding-block-end: 75%;
	}
	&.thebase-thumbnail-ratio-5-4 {
		padding-block-end: 125%;
	}
	&.thebase-thumbnail-ratio-4-3 {
		padding-block-end: 133%;
	}
	&.thebase-thumbnail-ratio-3-2 {
		padding-block-end: 150%;
	}
	&.thebase-thumbnail-ratio-inherit {
		padding-block-end: 0;
		height:auto;
		.post-thumbnail-inner {
			position: static;
		}
	}
	.post-thumbnail-inner {
		position: absolute;
		top: 0;
		bottom: 0;
		left: 0;
		right: 0;
		border-radius: 5px;	
	}
	img {
		flex: 1;
		height: 100%;
		object-fit: cover;
		width: 100%;
	}
}
.archive.content-style-unboxed .site .post-thumbnail, .blog.content-style-unboxed .site .post-thumbnail, .post-type-archive.content-style-unboxed .site .post-thumbnail, .search-results.content-style-unboxed .site .post-thumbnail {
    margin-block-end: 0;
}
.content-style-unboxed .thebase-thumbnail-position-above {
    margin-block-end:var(--global-md-spacing);
}
.thebase-thumbnail-position-behind {
    margin-block-end: -10em;
    position: relative;
    z-index: 0;
}
.content-style-unboxed .thebase-thumbnail-position-behind:after {
    position: absolute;
    content: '';
    top: 0;
    bottom: -1px;
    left: 0;
    right: 0;
    background: linear-gradient(0deg, var(--global-palette9) 5%, rgba(var(--global-palette9rgb),0.35) 30%, rgba(255,255,255,0) 50%);
}
.thebase-breadcrumbs {
	margin: 0.3em 0;
    color: var(--global-palette5);
	a {
		color: inherit;
		text-decoration: none;
	}
	p {
		margin: 0;
	}
}
.entry-content-wrap .entry-header .thebase-breadcrumbs:first-child {
    margin-block-start: 0;
}
.entry-meta {
	margin: 0 0 .6em;
	display: flex;
	flex-wrap: wrap;
	> * {
		display: inline-flex;
		align-items: center;
		flex-wrap: wrap;
		time {
			white-space: nowrap;
		}
	}
	a {
		color: inherit;
		text-decoration: none;
	}
	.meta-label {
		margin-inline-end: .2rem;
	}
	.author-avatar {
		width: 25px;
		height: 25px;
		margin-inline-end: .5rem;
		position: relative;
		display: inline-block;
		a.author-image {
			position: absolute;
			left: 0;
			right: 0;
			top: 0;
			bottom: 0;
		}
		.image-size-ratio {
			padding-block-end: 100%;
			display: block;
		}
		img {
			position: absolute;
			left: 0;
			top: 0;
			bottom: 0;
			right: 0;
			border-radius: 50%;
		}
	}
}
.primary-sidebar .wc-block-product-search__button {
	border-radius: 0;
}
.entry-meta>*:after {
    margin-inline-start: .5rem;
    margin-inline-end: .5rem;
    content: "";
    display: inline-block;
    background-color: currentColor;
    height: .25rem;
	width: .25rem;
	opacity: .8;
    border-radius: 9999px;
    vertical-align: .1875em;
}
.entry-meta.entry-meta-divider-slash>*:after {
    content: '/';
    height: auto;
    background: transparent;
}
.entry-meta.entry-meta-divider-dash>*:after {
    content: '-';
    height: auto;
    background: transparent;
}
.entry-meta.entry-meta-divider-vline>*:after {
    content: "";
	height: 1em;
	background: var(--global-gray-500);
	margin-top: 2px;
	width: 1px;
}
.entry-meta>*:last-child:after {
    display: none;
}
.entry-meta-divider-customicon.entry-meta>*>.thebase-svg-iconset {
    margin-inline-end: 0.3em;
    margin-inline-start: .8em;
}
.entry-meta-divider-customicon.entry-meta>*:after {
    display: none;
}
.loop-entry .entry-header .entry-title {    
	padding: 0;
}
.blog .entry-meta .posted-on::after {
	display: none;
}
.loop-entry .entry-summary p{
    margin: .6em 0 0	;
}

/* Hides the update date and time. */
.updated:not(.published) {
	display: none;
}
.updated-on .updated:not(.published) {
    display: inline-block;
}
body a.post-more-link {	
	font-size: 13px;
	font-weight: 500;
	text-transform: uppercase;
	.thebase-svg-iconset {
		display: none;
		margin-inline-start: 0.5em;
	}
	&:hover {				
		color: var(--global-palette1);
	}
}
.more-link-wrap {
    margin-block-start: 0;
    margin-block-end: 0;
}
.entry-taxonomies {
	margin-block: 0.5em;
	a {
		text-decoration: none;
	}
	.category-style-pill a {
		background: var(--global-palette-highlight);
		color: white;
		padding: 0.25rem;
		border-radius: 0.25rem;
		margin-inline-end: .25rem;
		margin-block-end: .25rem;
		display: inline-block;
		line-height: 1.2;
		&:last-child {
			margin-inline-end: 0;
		}
		&:hover {
			color: white;
		}
	}
	.category-style-underline a {
		text-decoration: underline;
	}
}
.loop-entry.type-post .entry-taxonomies .category-style-pill a {
	color:white;
}
@media screen and (max-width: $phone-minus-query) {
	.loop-entry .entry-taxonomies {
		margin-block-end: 1em;
	}
}
.entry-tags {
	margin-block-start: var(--global-xs-spacing);
	.tags-links {
		display: flex;
		flex-wrap: wrap;
	}	
	a.tag-link {
			-webkit-margin-end: .5em;
			margin-inline-end: .5em;
			padding: .4em .6em;
			-webkit-margin-before: .5em;
			margin-block-start: .5em;
			border: 1px solid var(--global-palette7);
			color: var(--global-palette1);
			text-decoration: none;
			background-color: var(--global-palette7);
		&:hover {
			background: var(--global-palette-highlight-alt);
			color: var(--global-palette-btn-hover);
			border-color: var(--global-palette-highlight-alt);
		}
		&:last-child {
			margin-inline-end: 0;
		}
	}
}
.no-img {
	.posted-on {
		position: relative;
		background: none;
		top: auto;
		left: auto;
		padding: 0;
	}
	.entry-meta.entry-meta-divider-vline {
		.posted-on {
			&::after {
				display: block;
			}
		}
	}
}
.entry-hero-container-inner {
	background: var( --global-palette7 );
	.entry-header {
		min-height: 200px;
		display: flex;
		align-items: center;
		text-align: center;
		justify-content: center;
		flex-direction: column;
		margin-block-end: 0;
		> div {
			width: 100%;
		}
	}
}
.entry-hero-layout-fullwidth > .entry-hero-container-inner > .site-container {
    max-width:none;
}
.entry-hero {
    position: relative;
}
.hero-section-overlay {
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
}
.hero-container {
    position: relative;
}
.title-align-left {
	text-align: left;
}
.title-align-center {
	text-align: center;
	.entry-meta {
		justify-content: center;
	}
}
.title-align-right {
	text-align: right;
	.entry-meta {
		justify-content: flex-end;
	}
}
.entry-hero {
	.title-align-left {
		text-align: left;
		align-items: flex-start;
	}
	.title-align-center {
		text-align: center;
		align-items: center;
	}
	.title-align-right {
		text-align: right;
		align-items: flex-end;
	}
}
@media screen and (max-width: $tablet-minus-query) {
.title-tablet-align-left {
	text-align: left;
}
.title-tablet-align-center {
	text-align: center;
	.entry-meta {
		justify-content: center;
	}
}
.title-tablet-align-right {
	text-align: right;
	.entry-meta {
		justify-content: flex-end;
	}
}
.entry-hero {
	.title-tablet-align-left {
		text-align: left;
		align-items: flex-start;
	}
	.title-tablet-align-center {
		text-align: center;
		align-items: center;
	}
	.title-tablet-align-right {
		text-align: right;
		align-items: flex-end;
	}
}
}
@media screen and (max-width: $phone-minus-query) {
.title-mobile-align-left {
	text-align: left;
}
.title-mobile-align-center {
	text-align: center;
	.entry-meta {
		justify-content: center;
	}
}
.title-mobile-align-right {
	text-align: right;
	.entry-meta {
		justify-content: flex-end;
	}
}
.entry-hero {
	.title-mobile-align-left {
		text-align: left;
		align-items: flex-start;
	}
	.title-mobile-align-center {
		text-align: center;
		align-items: center;
	}
	.title-mobile-align-right {
		text-align: right;
		align-items: flex-end;
	}
}
}
.entry-header {
    margin-block-end: .5em;
}
.page-content{
	margin: 2rem 0 0;
}
.page-header{
	margin-block-end: var(--global-lg-spacing);
}
.hero-container .page-header {
	margin-block-end: 0;
}

.entry-footer {
	clear: both;
}
// Post End
.post-navigation-wrap.entry-content-wrap, .post-navigation-wrap .post-navigation, .entry-author.entry-content-wrap {
    margin-block-start: 0;
    margin-block-end: 0;
    border-radius: 0;
}
.comments-area.entry-content-wrap {
    margin-block-start: 0;
    border-top-left-radius: 0;
    border-top-right-radius: 0;
}
.single-entry.post.entry.post-footer-area-boxed {
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
}
.post-footer-area-boxed ~ .entry-related {
    margin: 0;
    width: auto;
	max-width: 100%;
	.entry-related-inner-content.alignwide {
		margin: 0;
		width: auto;
		max-width: 100%;
	}
}

/*--------------------------------------------------------------
## Alignments
--------------------------------------------------------------*/

.wp-block-image .alignleft {
    margin-inline-end: var(--global-md-spacing);
}

.wp-block-image .alignright {
    margin-inline-start: var(--global-md-spacing);
}

.alignfull, .alignwide, .entry-content .alignwide, .entry-content .alignfull {
	margin-inline-start: calc(50% - 50vw);
	margin-inline-end: calc(50% - 50vw);
	max-width: 100vw;
	width: 100vw;
	padding-inline-start: 0;
    padding-inline-end: 0;
	clear:both;
}
@media screen and (min-width: $laptop-plus-query) {
	.content-width-narrow .entry-content .alignwide, .content-width-narrow .alignwide {
		margin-inline-start: -80px;
		margin-inline-end: -80px;
		width: auto;
	}
}
.content-width-fullwidth .site .alignwide {
	margin-inline-start: 0;
	margin-inline-end: 0;
	width: unset;
	max-width: unset;
}
.content-style-boxed .entry-content .alignwide {
	margin-inline-start: - 2rem;
	margin-inline-end: - 2rem;
	max-width: unset;
	width: unset;
}

/*--------------------------------------------------------------
## Custom block font sizes.
--------------------------------------------------------------*/

.has-small-font-size {
	font-size: $font-size-small;
}

.has-regular-font-size {
	font-size: $font-size-regular;
}

.has-large-font-size {
	font-size: $font-size-large;
}

.has-larger-font-size {
	font-size: $font-size-larger;
}

.entry-content-asset.videofit{
	position: relative;
    padding-block-start: 56.25%;
    overflow: hidden;
    max-width: 100%;
    height: auto;
	iframe {
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
	}
}
body.elementor-template-full-width, body.content-width-fullwidth {
	form.post-password-form {
		text-align: center;
		margin: var(--global-xxl-spacing) auto;
	}
}
body.content-style-unboxed {
	form.post-password-form {
		margin: var(--global-xxl-spacing) auto;
	}
}
// Edge Case.
.entry-title {
    word-wrap: break-word;
}
.entry-hero-layout-contained >.entry-hero-container-inner {
    position: relative;
}
body.content-style-boxed .wp-block-thebase-rowlayout.alignfull > .tb-row-layout-inner > .kb-theme-content-width {
    padding-inline-start: calc( var(--global-sm-spacing) + var(--global-boxed-spacing) );
    padding-inline-end: calc( var(--global-sm-spacing) + var(--global-boxed-spacing) );
}
.item-content-vertical-align-center .loop-entry .entry-content-wrap {
    display: flex;
    flex-direction:column;
    justify-content:center;
}
.item-content-vertical-align-center .loop-entry .entry-content-wrap .entry-header {
    margin-block-end: 0;
}
.item-content-vertical-align-center .loop-entry .entry-content-wrap .entry-summary {
    margin-block-start:0;
}
.item-content-vertical-align-center  .loop-entry .entry-summary p, .item-content-vertical-align-center  .loop-entry .entry-header .entry-meta {
    margin-block-end:0

}
.loop-entry.type-post {
	.sticky-inner {
		position: absolute;
		top: 15px;
		bottom: auto;
		left: 15px;
		right: auto;
		background: var(--global-palette2);
		color: var(--global-palette1);
		padding: 5px;
		border-radius: 5px;
		.sticky-post{
			color: var(--global-palette1);
			text-transform: capitalize;
			font-size: 14px;
			&:before{
			background: var(--global-palette1);
			}
		}
	}
}
.error404 {
	.error{
		text-align: center;
		h2{
			font-size: 120px;
			line-height: 1.2;
			font-weight: 500;
		}
		h3{
			font-size: 34px;
			line-height: 1.2;
			text-transform: inherit;
		}
	}
}